import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bidderType: "",
  numberOfApplicants: 1,
};

const bidderFormSlice = createSlice({
  name: "bidderForm",
  initialState,
  reducers: {
    type(state, action) {
      state.bidderType = action.payload.bidderType;
    },
    applicant(state, action) {
      state.numberOfApplicants = action.payload.applicant;
    },
  },
});

export const { type, applicant } = bidderFormSlice.actions;
export default bidderFormSlice.reducer;
