import React, { useContext, useState, useEffect } from "react";

const ApiContext = React.createContext();
const AppProvider = ({ children }) => {
  const API_END_URL = "/api/property-list";
  const API_URL = process.env.REACT_APP_API_PATH + API_END_URL;
  const [allProperties, setAllProperties] = useState("");
  
  // Fetch Properties Usign API
  const fetchProperties = async (url) => {
    try {
      const fetchData = await fetch(url);
      const propertiesData = await fetchData.json();
      // console.log(propertiesData);
      setAllProperties(propertiesData);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchProperties(API_URL);
  }, []);

  return (
    <ApiContext.Provider value={{ allProperties }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, AppProvider };
