import { configureStore } from "@reduxjs/toolkit";
import UserSlice from "./slices/UserSlice";
import BidderFormSlice from "./slices/BidderFormSlice";
import SearchFilterSlice from "./slices/SearchFilterSlice";
import NotificationSlice from "./slices/NotificationSlice";

const store = configureStore({
  reducer: {
    users: UserSlice,
    bidderForm: BidderFormSlice,
    searchFilter: SearchFilterSlice,
    notification: NotificationSlice,
  },
});

export default store;
