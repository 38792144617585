import { Routes, Route } from "react-router-dom";
import React, { Fragment, Suspense, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { login } from "./store/slices/UserSlice";

const Header = React.lazy(() => import("./components/Header/Header"));
const Home = React.lazy(() => import("./pages/home-page/Home"));
const ContactUs = React.lazy(() => import("./pages/contact-us/ContactUs"));
const AboutUs = React.lazy(() => import("./pages/about-us/AboutUs"));
const PropertyDetails = React.lazy(() =>
  import("./pages/property-details/PropertyDetails")
);
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const BiddingForm = React.lazy(() => import("./pages/bidding-form/BiddingForm"));
const LiveAuction = React.lazy(() => import("./pages/live-auction/LiveAuction"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const SignUp = React.lazy(() => import("./pages/auth/SignUp"));
const Loader = React.lazy(() => import("./components/Loader/index"));
const UpcomingAuctions = React.lazy(() =>
  import("./pages/upcoming-auctions/UpcomingAuctions")
);
const EditBiddingForm = React.lazy(() => import("./pages/bidding-form/EditBiddingForm"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const MyAccount = React.lazy(() => import("./pages/my-account/MyAccount"));
const MyApplicationsPage = React.lazy(() =>
  import("./pages/my-application/MyApplications")
);
const MyUpcomingAuctions = React.lazy(() =>
  import("./pages/my-upcoming-auctions/MyUpcomingAuctions")
);
const MyAuctionResults = React.lazy(() =>
  import("./pages/my-auction-results/MyAuctionResults")
);
const MyAuctionBidLogs = React.lazy(() =>
  import("./pages/my-auction-results/MyAuctionBidLogs")
);
const MyNotifications = React.lazy(() =>
  import("./pages/my-notifications/MyNotifications")
);
const MyWallet = React.lazy(() =>
  import("./pages/my-wallet/MyWallet")
);

function App() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedEncryptedToken = localStorage.getItem("token");
    const SecretKey = process.env.REACT_APP_SECRETE_KEY;
    if (storedEncryptedToken) {
      const { encryptedToken, expirationTimestamp } = JSON.parse(storedEncryptedToken);
      if (new Date().getTime() <= expirationTimestamp) {
        const decryptedTokenBytes = CryptoJS.AES.decrypt(encryptedToken, SecretKey);
        const token = decryptedTokenBytes.toString(CryptoJS.enc.Utf8);
        if (token) {
          dispatch(login({ token }));
        }
        setLoading(false);
      } else {
        localStorage.removeItem("token");
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Header />

          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              {/* <Route path='/' element={<Navigate to="/upcoming-auctions" />} /> */}
              <Route path="/upcoming-auctions" element={<UpcomingAuctions />} key="0" />
              <Route path="/live-auction/:propertyId" element={<LiveAuction />} />
              <Route path="/my-auctions" element={<UpcomingAuctions key="1" />} />
              <Route path="/private-treaty" element={<UpcomingAuctions key="2" />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<AboutUs />} />
              {/* <Route path="/property/:propertyTitle" element={<PropertyDetails/>}/> */}
              <Route
                path="/property/:propertyId/:propertyTitle"
                element={<PropertyDetails />}
              />
              <Route path="/bidder-detaills/:propertyId" element={<BiddingForm />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/my-wallet" element={<MyWallet />} />
              <Route path="/my-notifications" element={<MyNotifications />} />
              <Route path="/my-applications" element={<MyApplicationsPage />} />
              <Route
                path="/my-upcoming-auctions"
                element={<MyUpcomingAuctions key="3" />}
              />
              <Route path="/my-auction-results" element={<MyAuctionResults />} />
              <Route
                path="/my-auction-results/bidLogs/:propertyId"
                element={<MyAuctionBidLogs />}
              />
              <Route
                path="/edit-bidder-details/:propertyId"
                element={<EditBiddingForm />}
              />
              <Route
                path="/view-bidder-details/:propertyId"
                element={<EditBiddingForm />}
              />
              <Route path="/password-reset/:token" element={<ResetPassword />} />
            </Routes>
          </Suspense>
          <Footer />
        </Fragment>
      )}
    </>
  );
}

export default App;
