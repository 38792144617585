import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  notificationLength: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setLength(state, action) {
      state.notificationLength = action.payload;
    },
    setNotification(state, action) {
      state.notifications = action.payload;
    },
  },
});

export const { setLength, setNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
