import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchState: "",
  searchCity: "",
  searchLocality: "",
  searchPropertyType: "",
  searchBank: "",
};

const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    clearAll(state, action) {
      state.searchState = "";
      state.searchCity = "";
      state.searchLocality = "";
      state.searchPropertyType = "";
      state.searchBank = "";
    },
    stateInput(state, action) {
      state.searchState = action.payload;
    },
    cityInput(state, action) {
      state.searchCity = action.payload;
    },
    localityInput(state, action) {
      state.searchLocality = action.payload;
    },
    propertyTypeInput(state, action) {
      state.searchPropertyType = action.payload;
    },
    bankInput(state, action) {
      state.searchBank = action.payload;
    },
  },
});

export const {
  clearAll,
  stateInput,
  cityInput,
  localityInput,
  propertyTypeInput,
  bankInput,
} = searchFilterSlice.actions;
export default searchFilterSlice.reducer;
